const getWalletTypeDisplayText = (type, t) => {
    let displayText;
    switch (type) {
        case 'SPOT':
            displayText = t('portfolio.trade_types.spot').toUpperCase();
            break;
        case 'MARGIN':
            displayText = t('portfolio.trade_types.margin').toUpperCase();
            break;
        case 'FUTURES1':
        case 'FUTURES2':
        default:
            displayText = t('placeholders.unsupported', { ns: 'common' });
    }
    return displayText;
};
const getExchangeTypeDisplayText = (type, t) => {
    let displayText;
    switch (type) {
        case 'BINANCE':
            displayText = t('exchange.provider.binance');
            break;
        default:
            displayText = t('placeholders.unsupported', { ns: 'common' });
    }
    return displayText;
};
export { getWalletTypeDisplayText, getExchangeTypeDisplayText, };
