export const mapCurveDots = (data) => {
    const result = data.reduce((acc, val, index) => {
        const key = `${new Date(val[0]).getFullYear()}-${new Date(val[0]).getMonth()}-${new Date(val[0]).getDate()}`;
        return ({
            curveDots: Object.assign(Object.assign({}, acc.curveDots), { [key]: index }),
        });
    }, {
        curveDots: {},
    });
    return result;
};
export const defineTooltipPlacement = ({ tooltipWidth, coords, scaleX, scaleY, graphWidth, }) => {
    const displayBeforeCursor = scaleX(coords[0]) + tooltipWidth >= graphWidth;
    let tooltipLeft;
    const tooltipTop = scaleY(coords[1]);
    if (displayBeforeCursor) {
        tooltipLeft = scaleX(coords[0]) - tooltipWidth - 3;
    }
    else {
        tooltipLeft = scaleX(coords[0]) + 3;
    }
    return { tooltipLeft, tooltipTop };
};
