var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { exchangeApi, } from '@shared/api/exchange';
export const getExchanges = createAsyncThunk('exchanges/getExchangesList', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { rejectWithValue, dispatch }) {
    try {
        return yield dispatch(exchangeApi
            .endpoints
            .getExchangeAccounts
            .initiate()).unwrap();
    }
    catch (e) {
        return rejectWithValue(e);
    }
}));
export const getWalletsById = createAsyncThunk('exchanges/getWalletById', (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ externalFinanceAccountId, walletType, }, { rejectWithValue, dispatch }) {
    try {
        return yield dispatch(exchangeApi
            .endpoints
            .getWalletsByExchangeId
            .initiate({
            externalFinanceAccountId,
            walletType,
        })).unwrap();
    }
    catch (e) {
        return rejectWithValue(e);
    }
}));
export const getWalletsWithExchanges = createAsyncThunk('exchanges/getWalletsWithExchanges', (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { rejectWithValue, dispatch }) {
    try {
        const exchangesResponse = yield dispatch(getExchanges()).unwrap();
        const walletReqPromises = [];
        exchangesResponse.forEach((exchange) => {
            walletReqPromises.push(new Promise((resolve, reject) => {
                dispatch(getWalletsById({
                    externalFinanceAccountId: exchange.id,
                })).unwrap().then((walletsResponse) => { resolve(walletsResponse); }, (error) => { reject(error); });
            }));
        });
        const walletsListResponse = yield Promise.allSettled(walletReqPromises)
            .then((wallets) => {
            let resultList = [];
            wallets.forEach((resultItem) => {
                if (resultItem.status === 'fulfilled') {
                    resultList = [...resultList, ...(resultItem.value || [])];
                }
            });
            return resultList;
        });
        return { walletsList: walletsListResponse, exchanges: exchangesResponse };
    }
    catch (e) {
        return rejectWithValue(e);
    }
}));
