export const CONNECT_EXCHANGE_INIT_VALUES = {
    exchange: null,
    wallets: [],
};
export const CONNECT_BINANCE_EXCHANGE_INIT_VALUES = {
    name: '',
    apiKey: '',
    secretKey: '',
};
export const CREATE_PORTFOLIO_INIT_VALUES = {
    exchange: null,
    monthlyFee: 0,
    portfolioName: '',
    walletId: '',
    stepsHistory: [],
    exchangeName: '',
    apiKey: '',
    secretKey: '',
};
