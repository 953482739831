import { createSlice } from '@reduxjs/toolkit';
import { exchangeApi, } from '@shared/api/exchange';
import { getSidebarInitValues } from './utils';
const SliceName = 'exchange';
const initialState = {
    isSidebarOpen: false,
    sidebarType: null,
    sidebarFormValues: null,
    selectedExchange: null,
    selectedPortfolioId: undefined,
    haveWallets: false,
};
const exchangeSlice = createSlice({
    name: SliceName,
    initialState,
    reducers: {
        getFormInitValues: (state, { payload }) => {
            state.isSidebarOpen = true;
            state.sidebarType = payload;
            state.sidebarFormValues = getSidebarInitValues(payload, state.haveWallets);
        },
        resetFormValues: (state) => {
            state.isSidebarOpen = false;
            state.sidebarType = null;
            state.sidebarFormValues = null;
        },
        selectExchange: (state, { payload }) => {
            state.selectedExchange = payload;
        },
        selectPortfolio: (state, { payload }) => {
            state.selectedPortfolioId = payload;
        },
        removePortfolio: (state) => {
            state.selectedPortfolioId = undefined;
        },
        removeSelectedExchange: (state) => {
            state.selectedExchange = null;
        },
        updateSidebarValues: (state, { payload }) => {
            state.sidebarFormValues = Object.assign(Object.assign({}, state.sidebarFormValues), payload);
        },
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(exchangeApi.endpoints.getExchangeAccounts.matchFulfilled, (state, { payload }) => {
            state.haveWallets = payload.length > 0;
        });
    },
});
export { SliceName };
export const { getFormInitValues, resetFormValues, updateSidebarValues, selectExchange, selectPortfolio, removeSelectedExchange, removePortfolio, reset, } = exchangeSlice.actions;
export const exchangeReducer = exchangeSlice.reducer;
