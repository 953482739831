import { CONNECT_BINANCE_EXCHANGE_INIT_VALUES, CONNECT_EXCHANGE_INIT_VALUES, CREATE_PORTFOLIO_INIT_VALUES, } from './constants';
import { ExchangeSidebar } from './types';
export const getSidebarInitValues = (sidebarType, haveExchanges) => {
    switch (sidebarType) {
        case ExchangeSidebar.ConnectExchange:
            return CONNECT_EXCHANGE_INIT_VALUES;
        case ExchangeSidebar.ConnectBinanceExchange:
            return CONNECT_BINANCE_EXCHANGE_INIT_VALUES;
        case ExchangeSidebar.CreatePortfolio:
            return Object.assign(Object.assign({}, CREATE_PORTFOLIO_INIT_VALUES), { defaultStep: haveExchanges ? 2 : 0 });
        default:
            return null;
    }
};
export const mapWalletsOverview = (wallets, exchanges) => {
    const exchangesDict = exchanges.reduce((acc, exchange) => {
        acc[exchange.id] = Object.assign({}, exchange);
        return acc;
    }, {});
    return {
        walletsOverviewList: wallets.map((wallet) => (Object.assign(Object.assign({}, wallet), { 
            // TODO: add logic for defining prior type
            wallet_type: 'wallet', balance_diff_in_percentage: 0, balance_diff_in_currency: 0, exchange: exchangesDict[wallet.externalFinanceAccountId] }))),
        walletsForPortfolio: wallets.map((wallet) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, wallet), { exchangeType: (_a = exchangesDict[wallet.externalFinanceAccountId]) === null || _a === void 0 ? void 0 : _a.type, exchangeParentName: (_b = exchangesDict[wallet.externalFinanceAccountId]) === null || _b === void 0 ? void 0 : _b.properties.connectionName }));
        }),
    };
};
export const mapUsersPortfolios = ({ portfolios, wallets, }) => {
    const dict = wallets.reduce((acc, wallet) => {
        acc[wallet.id] = Object.assign({}, wallet);
        return acc;
    }, {});
    return portfolios.map((portfolio) => (Object.assign(Object.assign({}, portfolio), { wallet: dict[portfolio.id] })));
};
